import React from "react"
import { Layout } from "../components/Layout"
import { Helmet } from "react-helmet"
import {
  CTAButton,
  AttributeSection,
  GetStartedButton,
} from "../commonComponents"
import {
  IntegrationsMiddleSectionImageLeft,
  IntegrationsMiddleSectionImageRight,
} from "../integrationsMiddleSection"
import {
  IntegrationDarkSection,
  IntegrationHeroSection,
} from "../integrationsUtils"
import { capitalizeFirstLetter } from "../utils"

export default function GitHub() {
  const SERVICE_NAME = "github"
  const BACKGROUND_SRC = `/img/${SERVICE_NAME}-bg.png`
  const BACKGROUND_WEB_POSITION = "80%"
  const BACKGROUND_MOBILE_POSITION = "50% 50px"
  // It is done like this because the content is different in almost every page.
  return (
    <Layout
      keywords="github integration, github ticketing system, GitHub community support, github zendesk integration"
      title="Streamline Your Workflow With Github Ticketing System Integration"
      description="Effortlessly manage tasks and projects by integrating IrisAgent with GitHub ticketing system. Discover how this integration can streamline your workflow & productivity"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/github/"
        />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <IntegrationHeroSection
        serviceName={SERVICE_NAME}
        titleIntegration={
          <>
          <span className="web-view-new-design-display">
            Scale <span className="title-fucsia-color">community
              support </span> on GitHub<br/> with Ticketing <br/> system integration
          </span>
          <span className="mobile-view-new-design-display">
          Scale <span className="title-fucsia-color">community<br/>
              support </span> on GitHub<br/> with Ticketing <br/>System integration
          </span>
          </>
        }
        descriptionIntegration={
          <>
          <span className="web-view-new-design-display">
          Looking for top-tier customer support on GitHub? <br/>
          IrisAgent utilizes past issues and documentation<br/>
          to offer AI resolutions. With automated reporting<br/>
          issue tagging, you can trust IrisAgent to keep<br/>
          your Github support running smoothly.
          </span>
          <span className="mobile-view-new-design-display">
          Looking for top-tier customer <br/> support on GitHub?
          IrisAgent <br/>utilizes past issues and <br/>documentation
          to offer AI <br/>resolutions.
          </span>
          </>
        }
        sectionBackgroundSrc="/img/github-bg.webp"
        sectionBackgroundWebPosition={BACKGROUND_WEB_POSITION}
        sectionBackgroundMobilePosition={BACKGROUND_MOBILE_POSITION}
      />
      {/* <!-- End Hero --> */}

      {/*  ======= Cta Section =======  */}
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Scale your{" "}
            <span className="title-fucsia-color">customer support team</span>
          </span>
        }
        button={<GetStartedButton />}
        firstColClassName="col-md-8"
        secondColClassName="col-md-4"
        buttonWebFlexAlignment="end"
      />
      {/*  End Cta Section  */}

      {/* <!-- ======= Middle Section ======= --> */}


      <IntegrationsMiddleSectionImageRight
        mainImage="/img/githubMiddle2.webp"
        imageTextMiniature="/img/githubMiniature.webp"
        backgroundSectionColor="#F9F9F9"
        descriptionTextTitle={
          <>AI powered resolution of GitHub issues</>
        }
        descriptionTextContent={
          <>
            IrisAgent's AI enables fast resolution of Github issues by automatically suggesting answers from past issues and documentation and correlating them to known bugs and issues.
          </>
        }
      />
      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/freshworksMiddle3.webp"
        imageTextMiniature="/img/githubMiniature.webp"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={
          <>Automate tagging, routing and prioritizing within GitHub</>
        }
        descriptionTextContent={
          <>
            IrisAgent discovers tags customized to your domain and automatically
            tags new issues. Get rid of manual and inaccurate tagging. Increased visibility in top issues and concerns.
          </>
        }
      />

      {/* <!-- End Middle Section --> */}

      {/* //   <!-- ======= Attribute Section ======= --> */}
      <AttributeSection />
      {/* //   <!-- ======= END -- Attribute Section ======= --> */}
      {/* <!-- ======= Cta Section ======= --> */}

      {/* <!-- End Cta Section --> */}
      {/* <!-- ======= Cta Section ======= --> */}
      {/* <ContactSection></ContactSection> */}
      {/* <!-- End Cta Section --> */}
    </Layout>
  )
}
